/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Sep 18, 2019, 9:49:49 PM
    Author     : Administrator
*/

.logo_login{
    margin-top: -40%;
    margin-bottom: 25px;
    width: 160px;
}
.winDiv .formWrapper {
    background: #fff;
    padding: 40px;
   

}


.winDiv .btn-login{
    padding-right: 4em;
    padding-left: 4em;
    background-color: #21a2dc;

}
.bg-lightblue{
    background-color: #21A2DC; 
}
.bg-lightvariant{
    background-color: #5273EB;
}

.bg-lightorange{
    background-color: #955e37;
}


.bg-lightdarkorange{
    background-color: #B65C92;
}

.bg-lightviolate{
    background-color:#60479c;
}

.bg-lightgreen{
    background-color:#4d8657;
}

.bg-Rausch{
    background-color: #FF5A5F;
}

.bg-Foggy{
    background-color: #767676;
}

.bg-Arches{
    background-color: #C13584;
}
.bg-green{
    background-color: #0F9D58;
}

.custom-upper-text{
    text-transform: uppercase;
    font-weight: 100;
    display: block;
}