body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #444 !important;
}
.spinner-wide-handling {
    position: fixed;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    z-index: 1030;
}

.lazar_view .table thead th {
    vertical-align: middle;
    text-align: center;
}
.modal-dialog {
    max-width: inherit !important;
}
#nominiModel {
    width: 900px;
}
#nominiModel .modal-header {
    border-bottom: none;
}

#choiceModel {
    width: 450px;
}
.equal-width {
    width: 140px;
}


.cu-btn-danger {
    background-color: #bb5f89;
    box-shadow: 10px 8px 12px #dbdadb;
    color: #fff;
    border-radius: 0;
}

.cu-btn-waring {
    background-color: #e5782b;
    box-shadow: 10px 8px 12px #dbdadb;
    color: #fff;
    border-radius: 0;
}

.cu-btn-dark {
    background-color: #444444;
    box-shadow: 10px 8px 12px #dbdadb;
    color: #fff;
    border-radius: 0;
}

.cu-btn-primary {
    background-color: #21a2dc;
    box-shadow: 10px 8px 12px #dbdadb;
    color: #fff;
    border-radius: 0;

}

.cu-btn-violet {
    background-color: #8640fa;

    color: #fff;
    border-radius: 0;


}


.sa-custome-select {
    border: none !important;
    border-bottom: 2px solid #eee !important;
}
.custome-select {
    background: url("/assets/img/arrow.jpg") no-repeat right 0 center/35px 100%;
    color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input-group {
    z-index: 0;
}
.loginWrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    height: calc(100vh - 60px);
}
.verticalMiddle {
    display: table;
    height: 100%;
    width: 100%;
}
.verticalMiddle .verticalMiddle_ {
    display: table-cell !important;
    vertical-align: middle;
}
.winDiv {
    max-width: 400px;
    margin: 0 auto;
}


.sidenav {
    height: 100%; /* 100% Full-height */
    width: 45%; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 2; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black*/
    background-image: linear-gradient(#33728e, #60479c, #955e36);
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */

.sidenav .closebtn {
    color: #fff;
}

.sidenav a:not(:first-child) {
    padding: 125px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    transition: 0.3s;
    text-align: center;
    text-transform: uppercase;
    font-weight: 200;


}

.sidenav a:hover {
    color: #f1f1f1;
    cursor: pointer;
    text-decoration: none;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 70px;
    font-weight: 100;
}
.off-canvas {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: -15px;


}


.equalHeight {
    margin-bottom: 30px;
}
.equalHeight a {
    height: 100%;
    text-align: center;
    display: block;
    text-decoration: none;
}
.hover_effect {
    opacity: .7;
}
.hover_effect:hover {
    opacity: 1;
    text-decoration: none;
}
.hover_effect a:hover {
    text-decoration: none;
}

.custom-info {
    background-color: #fff;
}


.customBreadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
}

.customBreadcrumb .breadcrumb {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.customBreadcrumb .breadcrumb li a {
    color: #fff;
}
.customBreadcrumb .breadcrumb li span.active {
    color: #e5782b;

}
.bg-custom-info .card-header {
    border-bottom: none;
    background-color: #fff;
}

.steps-form .steps-row {
    display: table-row;
}
.steps-form {
    display: table;
    width: 100%;
    position: relative;
}

.steps-form .steps-row::before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
}
.steps-form .steps-row .steps-step {
    display: table-cell;
    text-align: center;
    position: relative;
}
.steps-form .steps-row .steps-step p {
    padding-top: 0.5rem;
}
.steps-form .steps-row .steps-step .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
    margin-top: 0;
}
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-indigo {
    color: #fff !important;
    background-color: #3f51b5 !important;
}
.btn {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.md-form {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.md-form label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    color: #757575;
    cursor: text;
    -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    transition: transform 0.2s ease-out, color 0.2s ease-out, -webkit-transform 0.2s ease-out;
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
}

.md-form input:not([type]),
.md-form input[type="text"]:not(.browser-default),
.md-form input[type="password"]:not(.browser-default),
.md-form input[type="email"]:not(.browser-default),
.md-form input[type="url"]:not(.browser-default),
.md-form input[type="time"]:not(.browser-default),
.md-form input[type="date"]:not(.browser-default),
.md-form input[type="datetime"]:not(.browser-default),
.md-form input[type="datetime-local"]:not(.browser-default),
.md-form input[type="tel"]:not(.browser-default),
.md-form input[type="number"]:not(.browser-default),
.md-form input[type="search"]:not(.browser-default),
.md-form input[type="search-md"],
.md-form textarea.md-textarea {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background-color: transparent;
    border: none;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.md-form input:not([type]):focus:not([readonly]),
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #4285f4;
    -webkit-box-shadow: 0 1px 0 0 #4285f4;
    box-shadow: 0 1px 0 0 #4285f4;
}


.md-form .form-control {
    height: auto;
    padding: 0.6rem 0 0.4rem;
    margin: 0 0 0.5rem;
    background-color: transparent;
    border-radius: 0;
}
.md-form label.active {
    -webkit-transform: translateY(-14px) scale(0.8);
    transform: translateY(-14px) scale(0.8);
}
.custom-card .cu-no-card-img-top {
    width: 170px;
    margin-top: -5em;
    margin-left: 2em;
}

.custom-card .cu-card-img-top {
    width: 170px;
    margin-top: -5em;
    margin-left: 3em;
}


.cu-change-hover {
    margin-top: -9em;

}
.custom-info thead {

    background-color: #138496;;
    color: #fff;
}

.custom-warring thead {

    background-color: #e5782b;
    color: #fff;
}

.custom-warring th,
.custom-warring td,
thead th,.table th {
    white-space: nowrap;
    border: 2px solid #444444;
    border-top: 0 !important;
}
.custom-warring td {
    background-color: #fff;
}

.disabled{
    opacity: .5;
}


@media(min-width: 767.98px) and(max-width: 1024px) {
    .custom-card img {
        margin-left: 3em;
    }
}

@media(max-width: 425px) {
    .sidenav {
        width: 100%;
    }
    .mainContent {
        margin-top: 1em;
    }
    .custom-card img {
        margin-left: 4em;
    }
}
